@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter var';
  src: url('./assets/fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('./assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

html,
#root {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: underline;
  text-decoration-color: #6b7280;
}

h3 {
  font-family: 'Inter-SemiBold';
  font-style: normal;
  font-size: 24px;
  line-height: 150%;
}

[type='radio'] {
  background: #f9fafb;
  border: 3px solid #6b7280;
}

[type='radio']:checked {
  background: #76a9fa;
  border: 3px solid #1c64f2;
}

input:hover + .tooltip,
input:focus + .autocomplete {
  display: block !important;
}

.datepicker-picker {
  border-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.datepicker-dropdown {
  padding: 0 !important;
}

div[role='tablist'] {
  position: sticky;
  top: 0;
}

h1 {
  font-size: 2.25em; /* 36px */
  font-weight: bold;
  margin: 1em 0;
  color: #333;
  font-family: 'Inter var';
}

h2 {
  font-size: 1.75em; /* 28px */
  font-weight: bold;
  margin: 1em 0;
  color: #333;
  font-family: 'Inter var';
}

h3 {
  font-size: 1.5em; /* 24px */
  font-weight: bold;
  margin: 1em 0;
  color: #333;
  font-family: 'Inter var';
}
h4 {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

p {
  color: #000;
  font-size: 14px;
  font-family: 'Inter var';
}

ul {
  padding-left: 1.5em;
}

li {
  margin-bottom: 0.5em;
  color: #333;
}

.download-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
}

.download-item h2 {
  margin: 0 0 10px 0;
}

pre {
  background: #eee;
  padding: 10px;
  border-radius: 5px;
}

ol ol {
      margin-left: 40px;
    }
